@media (max-width: 720px) {
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 93%;
  }

  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}

::selection {
  background-color: #af0104;
  color: #ffffff;
}

* {
  font-family: "Prompt", sans-serif !important;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: "touch";
}

body {
  margin: 0;
  padding: 0;
  height: 93%;
}

#root {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
}

.overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.text::before {
  width: 100%;
  color: white;
  border: none;
  margin-left: auto;
  padding-left: 0;
  margin-right: 0;
  zoom: 1;
}

.container::after {
  content: "\0020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.css-nrdprl-MuiTypography-root {
  color: white !important;
}

.accordion {
  box-shadow: none !important;
}

.MuiAccordionSummary-content {
  justify-content: end;
  padding-right: 2%;
}

.text {
  width: 80% !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Zone Css Swiper */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.image {
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(125, 11, 11, 0.6);
}

.container_img:hover .overlay {
  opacity: 1;
}

.textShow {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cardimage {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.cardcontainer:hover .cardimage {
  opacity: 0.3;
}

.cardcontainer:hover .middle {
  opacity: 1;
}

.textShowNews {
  color: #af0104;
  padding: 16px 32px;
}

.errorText {
  color: #af0104;
  font-size: 16px;
}

.swiper-button-prev,
.swiper-button-next {
  color: #af0104;
}

.swiper-pagination-bullet-active {
  background: #af0104;
}

.text-bold {
  font-weight: 600 !important;
}

/* .text-normal {
  font-size: 20px !important;
} */
